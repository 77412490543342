import React from "react";
import { Router } from "@reach/router";

import SimListingPage from "../components/PageComponents/SimListingPage";
import SimAddPage from "../components/PageComponents/SimAddPage";
import SimUpdatePage from "../components/PageComponents/SimUpdatePage";

const sims = (props) => (
  <Router>
    <SimListingPage {...props} path="/sims" />
    <SimAddPage {...props} path="/sims/add-sim" />
    <SimUpdatePage {...props} path="/sims/:simId" />
  </Router>
);

export default sims;
