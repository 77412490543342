import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  useTheme,
} from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import moment from "moment";
import { Field, Form as FinalForm } from "react-final-form";
import { useLocation } from "@reach/router";

import { get as GET, post } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Button from "../../base/Button";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import Icon from "../../Icon";
import { STATUS, TOTAL_JUMP_PAGES } from "../../../utilities/constants";
import Modal from "../../base/Modal";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import dashboardConstants from "../../../utilities/dashboard-constants";
import responsiveCss from "../../../utilities/responsiveCss";
import generateSearchString from "../../../utilities/generate-search-string";
import searchMultiple from "../../../utilities/searchMultiple";
import Dropdown from "../../base/Dropdown";
import { required } from "../../../validations";

const SimListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [sim, setSim] = useState(null);
  const [simData, setSimData] = useState({});
  const [sample, setSample] = useState(true);
  const [searchValue, setSearchValue] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const companyOptions = [
    { label: "All", value: "All" },
    { label: "Telcel", value: "Telcel" },
    { label: "Altan", value: "Altan" },
    { label: "ATT", value: "ATT" },
  ];

  const statusOptions = [
    { label: "All", value: "All" },
    { label: "Ready", value: "Ready" },
    { label: "Inactive", value: "Inactive" },
    { label: "Lost", value: "Lost" },
    { label: "Damaged", value: "Damaged" },
  ];
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "sims",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  const sampleToggle = () => {
    setSample(!sample);
  };
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/listing-sims?${generateSearchString(query)}`);
      if (data) {
        const filteredSimData = searchValue
          ? searchMultiple(get(data, "data", []), searchValue)
          : get(data, "data", []);
        setSimData({ ...data, data: filteredSimData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location, sample]);
  const tableHeader = [
    { label: "ICC", value: "icc" },
    { label: "ISDN", value: "isdn" },
    { label: "Compañía", value: "company" },
    { label: "Estatus", value: "status" },
    { label: "Fecha de creación", value: "creationDate" },
    { label: "Acciones", value: "actions" },
  ];
  const pageTitle = "Listado de SIMs";
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route isPrivate layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              navigate("/sims/add-sim");
            }}
          >
            Añadir nuevo SIM
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, "row"],
                    mb: 8,
                  }}
                >
                  <Box
                    as="input"
                    id="icc"
                    name="icc"
                    onChange={(e) => {
                      setSearchValue({ ...searchValue, icc: e.target.value });
                      sampleToggle();
                    }}
                    placeholder="ICC"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as="input"
                    id="isdn"
                    name="isdn"
                    onChange={(e) => {
                      setSearchValue({
                        ...searchValue,
                        isdn: e.target.value,
                      });
                      sampleToggle();
                    }}
                    placeholder="ISDN"
                    sx={{
                      ...theme.variant.input.primary,
                      // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="company"
                    onChange={(value) => {
                      setSearchValue({
                        ...searchValue,
                        company: value,
                      });
                      sampleToggle();
                    }}
                    options={companyOptions}
                    placeholder="Compañia"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2 }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="status"
                    onChange={(value) => {
                      setSearchValue({
                        ...searchValue,
                        status: value,
                      });
                      sampleToggle();
                    }}
                    options={statusOptions}
                    placeholder="Estatus"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2 }}
                  />
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(simData, "data", []).map((sim) => [
                  get(sim, "icc", "-"),
                  get(sim, "isdn", "-"),
                  get(sim, "company", "-"),
                  get(sim, "status", "-"),
                  get(sim, "purchased")
                    ? moment(get(sim, "purchased")).format("LLLL")
                    : "-",
                  <Menu>
                    <MenuButton
                      aria-label="Options"
                      as={IconButton}
                      icon={
                        <Icon svg="more-horizontal" sx={{ width: "20px" }} />
                      }
                      sx={{
                        "&:hover": {
                          bg: "transparent",
                        },
                        ":active": {
                          bg: "transparent",
                        },
                        bg: "transparent",
                      }}
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          navigate(`/sims/${get(sim, "sim_id")}`, {
                            state: { sim },
                          });
                        }}
                        sx={{
                          "&:hover": {
                            bg: "lightGreen",
                          },
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setSim(get(sim, "sim_id"));
                          deleteToggle();
                        }}
                        sx={{
                          "&:hover": {
                            bg: "lightGreen",
                          },
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>,
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {simData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(simData, "data", []).length}
                  total={get(simData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
        <Modal
          bodySx={{ pb: 0 }}
          footer={
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                onClick={async () => {
                  const data = await post("/del-sim", { sim_id: sim });
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm", "Internal server Error")
                        : "Sim Deleted Successfully!",
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                  deleteToggle();
                  sampleToggle();
                }}
                sx={{ mr: 3 }}
              >
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  deleteToggle();
                }}
                sx={{ ml: 3 }}
              >
                Cancel
              </Button>
            </Box>
          }
          onClose={deleteToggle}
          open={deleteModal}
        >
          <Box
            sx={{
              color: "mediumGreen",
              display: "flex",
              justifyContent: "center",
              my: 5,
            }}
          >
            Are you sure to delete this sim?
          </Box>
        </Modal>
      </Section>
    </Route>
  );
};

export default SimListingPage;
