import React from "react";
import { useTheme, useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import Input from "../../base/Input";
import { required } from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Heading from "../../base/Heading";
import { post, put } from "../../../utilities/http";
import CustomCheck from "../../base/CustomCheck";
import Icon from "../../Icon";
import { STATUS } from "../../../utilities/constants";

const AddUpdateSimForm = ({ location }) => {
  const theme = useTheme();
  const toast = useToast();
  const gap = 7;
  const sim = get(location, "state.sim", null);
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "60%",
    mb: gap,
    mx: 4,
    width: "60%",
  };
  const colFull = {
    minWidth: "100%",
  };
  const companyOptions = [
    { label: "Telcel", value: "Telcel" },
    { label: "Altan", value: "Altan" },
    { label: "ATT", value: "ATT" },
  ];

  const statusOptions = [
    { label: "Ready", value: "Ready" },
    { label: "Inactive", value: "Inactive" },
    { label: "Lost", value: "Lost" },
    { label: "Damaged", value: "Damaged" },
  ];
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: ["90%", null, null, "70%"],
            mb: 4,
            mx: "auto",
          }}
        >
          <Heading {...theme.variant.heading}>
            {sim ? "SIM de actualización" : "Añadir nuevo SIM"}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: "white",
            borderRadius: 4,
            maxWidth: "90%",
            mx: "auto",
            px: 5,
            py: 10,
          }}
        >
          <FinalForm
            initialValues={{
              company: get(sim, "company", "Telcel"),
              icc: get(sim, "icc"),
              isdn: get(sim, "isdn"),
              purchased: get(sim, "purchased"),
              status: get(sim, "status", "Ready"),
            }}
            onSubmit={async (values) => {
              try {
                const payload = sim
                  ? {
                      sim_id: get(sim, "sim_id"),
                      status: values.status,
                    }
                  : {
                      company: values.company,
                      icc: values.icc,
                      isdn: values.isdn,
                      purchased: values.purchased,
                      status: values.status,
                    };
                let data;
                let message;
                if (sim) {
                  data = await put("/update-sim", payload);
                  message = data && "Sim updated!";
                } else {
                  data = await post("/add-sim", payload);
                  message =
                    get(data, "success") === STATUS.INACTIVE
                      ? get(data, "msm", "Internal server Error")
                      : "Sim Added!";
                }
                if (data) {
                  toastMessage({
                    message,
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                }
                navigate("/sims");
              } catch (e) {
                toastMessage({
                  message: e.message,
                  toast,
                  type: "error",
                });
              }
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    as={Field}
                    component={Input}
                    disabled={!!sim}
                    name="icc"
                    placeholder="ICC"
                    type="number"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    disabled={!!sim}
                    name="isdn"
                    placeholder="ISDN"
                    type="number"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />

                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "center",
                      mb: gap,
                      mx: 4,
                      width: "60%",
                    }}
                  >
                    <Box
                      as="label"
                      sx={{
                        alignSelf: "center",
                        color: "text.primary",
                        display: "block",
                        fontSize: 1,
                        fontWeight: "regular",
                        letterSpacing: 0,
                        mr: 3,
                        my: 2,
                        width: ["50%", null, "25%"],
                      }}
                    >
                      *Compañía:
                    </Box>
                    {companyOptions.length > 0 &&
                      companyOptions.map((option) => (
                        <Box
                          sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            m: [3, null, 0],
                            width: ["50%", null, "25%"],
                          }}
                        >
                          <Box
                            key={option.value}
                            as={Field}
                            component={CustomCheck}
                            customActiveContent={
                              <Icon svg="radioActive" sx={{ width: "24px" }} />
                            }
                            customContent={
                              <Icon
                                svg="radioInactive"
                                sx={{ width: "24px" }}
                              />
                            }
                            disabled={!!sim}
                            htmlFor={option.value}
                            label={option.label}
                            labelSx={{
                              display: "flex",
                              flexWrap: "wrap",
                              fontSize: 1,
                              fontWeight: "regular",
                            }}
                            name="company"
                            onClick={() => {}}
                            type="radio"
                            validate={required}
                            value={option.value}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "center",
                      mb: gap,
                      mx: 4,
                      width: "60%",
                    }}
                  >
                    <Box
                      as="label"
                      sx={{
                        alignSelf: "center",
                        color: "text.primary",
                        display: "block",
                        fontSize: 1,
                        fontWeight: "regular",
                        letterSpacing: 0,
                        mr: 3,
                        my: 2,
                        width: ["50%", null, "25%"],
                      }}
                    >
                      *Estatus:
                    </Box>
                    {statusOptions.length > 0 &&
                      statusOptions.map((option) => (
                        <Box
                          sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            m: [3, null, 0],
                            width: ["50%", null, "25%"],
                          }}
                        >
                          <Box
                            key={option.value}
                            as={Field}
                            component={CustomCheck}
                            customActiveContent={
                              <Icon svg="radioActive" sx={{ width: "24px" }} />
                            }
                            customContent={
                              <Icon
                                svg="radioInactive"
                                sx={{ width: "24px" }}
                              />
                            }
                            htmlFor={option.value}
                            label={option.label}
                            labelSx={{
                              display: "flex",
                              flexWrap: "wrap",
                              fontSize: 1,
                              fontWeight: "regular",
                            }}
                            name="status"
                            onClick={() => {}}
                            type="radio"
                            validate={required}
                            value={option.value}
                          />
                        </Box>
                      ))}
                  </Box>
                  {sim ? (
                    <Box
                      as={Field}
                      component={Input}
                      disabled={!!sim}
                      name="purchased"
                      validate={required}
                      width="100%"
                      wrapperSx={colStyled}
                    />
                  ) : (
                    <Box
                      as={Field}
                      component={Input}
                      disabled={!!sim}
                      name="purchased"
                      type="datetime-local"
                      validate={required}
                      width="100%"
                      wrapperSx={colStyled}
                    />
                  )}
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      ...colFull,
                    }}
                  >
                    <Button
                      disabled={formContext.pristine || formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ mr: 3, width: "130px" }}
                      type="submit"
                      variant="primary"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/sims");
                      }}
                      sx={{ ml: 3, width: "130px" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Section>
    </Route>
  );
};

export default AddUpdateSimForm;
